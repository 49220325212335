import { FC } from 'react'

import type {
  AuthType,
  AuthValueType,
  CompanyAppDomain,
  CompanyConfigTimezone,
} from 'core/types'
import _ from 'lodash'
import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import SelectInput from 'peach/components/SelectInput'
import { useApi, useWrite } from 'peach/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'

import { Error, FormActions, Section, SectionLabel, Title } from './Components'
import { Company } from './types'
import { booleanOptions } from './utils'

type CreateCompanyFormValues = {
  legalName?: string | null
  slug?: string | null
  domainName?: string | null
  shortDomainName?: string | null
  type?: string | null
  name?: string | null
  brandName?: string | null
  borrowerAuthType?: AuthType | null
  agentAuthType?: AuthType | null
  borrowerAuthValueType?: AuthValueType | null
  agentAuthValueType?: AuthValueType | null
  femaPolicy?: boolean | null
  bankruptcyPolicy?: boolean | null
  deceasedPolicy?: boolean | null
  bankruptcyServiceId?: number | null
  deceasedServiceId?: number | null
  femaServiceId?: number | null
  scraServiceId?: number | null
  ofacServiceId?: number | null
  rndServiceId?: number | null
  appDomains?: CompanyAppDomain[] | null
  timezone?: CompanyConfigTimezone | null
  tokenizationKeyId?: number | null
  status?: 'active' | 'activeChurning' | 'inactive' | null
  config?: null | {
    borrowerVerification?: {
      crmDefaultTypeId?: string
    }
    communication?: {
      email_from?: {
        name?: string
        email?: string
      }
      address_from?: {
        city?: string
        state?: string
        postalCode?: string
        addressLine1?: string
        addressLine2?: string
      }
      text_from_number?: string
      enableInboundWebChat?: boolean
      enableInboundVoiceCall?: boolean
      enable_two_way_texting?: boolean
    }
    support?: {
      phone?: string
      email?: string
      departmentName?: string
      mailingAddress?: {
        city?: string
        state?: string
        postalCode?: string
        addressLine1?: string
        addressLine2?: string
      }
    }
    payment?: {
      processedByPeach?: boolean
      transactionLimitAmount?: number
    }
    paymentTypes?: {
      ach?: boolean
      check?: boolean
      debitCard?: boolean
      creditCard?: boolean
      balanceCheck?: {
        plaid?: boolean
      }
      achVerification?: {
        plaid?: boolean
        microdeposit?: boolean
      }
    }
    timezone?: string
    openHours?: {
      businessHours?: {
        normal?: string
      }
    }
    brandAssets?: {
      logos?: {
        small?: string
        primary?: string
        appLargeCentered?: string
        appCompactNavigation?: string
        appRegularNavigation?: string
        appCompactLeftNavigation?: string
        appRegularLeftNavigation?: string
      }
      colors?: {
        primary?: string
        tertiary?: string
        secondary?: string
      }
      copies?: {
        consent?: string
        disclosures?: string
      }
    }
    requireBorrowerIdentity?: boolean
    collections?: {
      intensityLimits?: {
        [key: string]: {
          heavy?: number
          normal?: number
          light?: number
        }
      }
    }
    links?: {
      helpUrl?: string
      termsOfUseUrl?: string
      privacyPolicyUrl?: string
    }
    statements?: {
      statementsEnabled?: boolean
      isMailStatementAllowed?: boolean
    }
  }
}

type CreateCompanyProps = {
  updateCompany: (obj: Company) => void
  title: string
}

const CreateCompany: FC<CreateCompanyProps> = ({ updateCompany, title }) => {
  const api: any = useApi()

  const [onCreate, loading, , error, clear] = useWrite((values) => {
    return api.companies.companies.post({
      body: values,
    })
  })

  const form = useForm<CreateCompanyFormValues>({
    defaultValues: {
      legalName: '',
      slug: '',
      domainName: '',
      shortDomainName: '',
      name: '',
      brandName: '',
      borrowerAuthType: 'oneTimeCodeEmail',
      agentAuthType: 'oneTimeCodeEmail',
      borrowerAuthValueType: 'email',
      agentAuthValueType: 'email',
      tokenizationKeyId: 567,
      status: 'active',
      config: {
        links: {
          helpUrl: '',
          termsOfUseUrl: '',
          privacyPolicyUrl: '',
        },
        payment: {
          processedByPeach: true,
          transactionLimitAmount: 100000,
        },
        support: {
          email: '',
          phone: '(888) 555-1234',
          departmentName: 'Support',
          mailingAddress: {
            city: 'Montreal',
            state: 'QC',
            postalCode: 'H2Y 2W2',
            addressLine1: `500 Place d'Armes`,
            addressLine2: 'Suite 1800',
          },
        },
        timezone: 'America/New_York',
        openHours: {
          businessHours: {
            normal: 'Mon-Fri 10:00-18:00',
          },
        },
        statements: {
          statementsEnabled: true,
          isMailStatementAllowed: false,
        },
        brandAssets: {
          logos: {
            small:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            primary:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            appLargeCentered:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            appCompactNavigation:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            appRegularNavigation:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            appCompactLeftNavigation:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
            appRegularLeftNavigation:
              'https://storage.googleapis.com/peach-assets-dev/slugname/slugname.svg',
          },
          colors: {
            primary: '#002237',
            tertiary: '#009FFF',
            secondary: '#59CD90',
          },
          copies: {
            consent: '',
            disclosures: '',
          },
        },
        collections: {
          intensityLimits: {
            7: {
              heavy: 5,
              light: 1,
              normal: 3,
            },
            30: {
              heavy: 15,
              light: 3,
              normal: 8,
            },
          },
        },
        paymentTypes: {
          ach: true,
          check: false,
          debitCard: false,
          creditCard: false,
          balanceCheck: {
            plaid: true,
          },
          achVerification: {
            plaid: true,
            microdeposit: true,
          },
        },
        communication: {
          email_from: {
            name: '',
            email: '',
          },
          address_from: {
            city: 'Montreal',
            state: 'QC',
            postalCode: 'H2Y 2W2',
            addressLine1: `500 Place d'Armes`,
            addressLine2: 'Suite 1800',
          },
          text_from_number: '+18005551234',
          enableInboundWebChat: true,
          enableInboundVoiceCall: true,
          enable_two_way_texting: true,
        },
        requireBorrowerIdentity: true,
      },
    },
  })

  const onSubmit = form.handleSubmit(async (values) => {
    try {
      if (error) clear()
      const resp = await onCreate(
        R.pipe(
          values ?? {},
          R.pickBy((v) => v !== null && !Number.isNaN(v)),
        ),
      )

      if (resp?.data?.id) {
        updateCompany({
          companyId: resp?.data.id,
          legalName: resp?.data?.legalName,
          name: resp?.data?.name,
          brandName: resp?.data?.brandName,
          completed: false,
        })
      }
    } catch (e) {
      console.log('!!e', e)
    }
  })

  const authTypeOptions = [
    { value: 'basic', label: 'Basic' },
    { value: 'google', label: 'Google' },
    { value: 'oneTimeCodeEmail', label: 'One Time Code Email' },
    { value: 'oneTimeCodeText', label: 'One Time Code Text' },
    { value: 'SAML', label: 'SAML' },
  ]

  const authTypeValueOptions = [
    { value: 'email', label: 'Email' },
    { value: 'username', label: 'Username' },
    { value: 'phone', label: 'Phone' },
    { value: 'samlSubjectNameId', label: 'SAML Subject Name ID' },
  ]

  return (
    <LoadingContainer loading={loading}>
      <Title>{title}</Title>
      <form onSubmit={onSubmit}>
        {error && <Error>{error.message}</Error>}
        <FormProvider {...form}>
          <Input
            type='text'
            label='Legal Name'
            placeholder='Company, Inc.'
            required
            {...(form.register('legalName'),
            {
              onChange: (e) => {
                form.setValue('legalName', e.target.value)
                form.setValue('slug', _.kebabCase(e.target.value))
              },
            })}
          />
          <Input
            type='text'
            label='Slug'
            placeholder='company-inc'
            {...form.register('slug')}
          />
          <Input type='text' label='Name' {...form.register('name')} />
          <Input
            type='text'
            label='Brand Name'
            {...form.register('brandName')}
          />

          <Input
            type='text'
            label='Domain Name'
            {...form.register('domainName')}
          />
          <Input
            type='text'
            label='Short Domain Name'
            placeholder='c.peach.to'
            {...form.register('shortDomainName')}
          />
          {/* <Input type='text' label='Type' {...form.register('type')} /> */}

          <SelectInput
            control={form.control}
            name='borrowerAuthType'
            options={authTypeOptions as any}
            placeholder='Select a borrower auth type'
            label='Borrower Auth Type'
          />

          <SelectInput
            control={form.control}
            name='agentAuthType'
            options={authTypeOptions as any}
            placeholder='Select an agent auth type'
            label='Agent Auth Type'
          />

          <SelectInput
            control={form.control}
            name='borrowerAuthValueType'
            options={authTypeValueOptions as any}
            placeholder='Select a borrower auth type value'
            label='Borrower Auth Value Type'
          />

          <SelectInput
            control={form.control}
            name='agentAuthValueType'
            options={authTypeValueOptions as any}
            placeholder='Select an agent auth type value'
            label='Agent Auth Value Type'
          />

          <Input
            type='number'
            label='Tokenization Key ID'
            {...form.register('tokenizationKeyId', { valueAsNumber: true })}
          />

          <SelectInput
            control={form.control}
            name='status'
            options={[
              { value: 'active', label: 'Active' },
              { value: 'activeChurning', label: 'Active Churning' },
              { value: 'inactive', label: 'Inactive' },
            ]}
            placeholder='Select a status'
            label='Status'
          />

          <Section>
            <SectionLabel>Config</SectionLabel>

            <Section>
              <SectionLabel>Borrower Verification</SectionLabel>
              <Input
                type='text'
                label='CRM Default Type Id'
                {...form.register(
                  'config.borrowerVerification.crmDefaultTypeId',
                )}
              />
            </Section>

            <Section>
              <SectionLabel>Communication</SectionLabel>

              <Section>
                <SectionLabel>Email From</SectionLabel>
                <Input
                  type='text'
                  label='Name'
                  placeholder='Company Customer Service'
                  {...form.register('config.communication.email_from.name')}
                />
                <Input
                  type='text'
                  label='Email'
                  placeholder='support@company-borrower.peach-sandbox.finance'
                  {...form.register('config.communication.email_from.email')}
                />
              </Section>

              <Section>
                <SectionLabel>Address From</SectionLabel>
                <Input
                  type='text'
                  label='City'
                  {...form.register('config.communication.address_from.city')}
                />
                <Input
                  type='text'
                  label='State'
                  {...form.register('config.communication.address_from.state')}
                />
                <Input
                  type='text'
                  label='Postal Code'
                  {...form.register(
                    'config.communication.address_from.postalCode',
                  )}
                />
                <Input
                  type='text'
                  label='Address Line 1'
                  {...form.register(
                    'config.communication.address_from.addressLine1',
                  )}
                />
                <Input
                  type='text'
                  label='Address Line 2'
                  {...form.register(
                    'config.communication.address_from.addressLine2',
                  )}
                />
              </Section>
              <Input
                type='text'
                label='Text From Number'
                {...form.register('config.communication.text_from_number')}
              />

              <SelectInput
                control={form.control}
                name='config.communication.enableInboundWebChat'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Enable Inbound Web Chat'
              />
              <SelectInput
                control={form.control}
                name='config.communication.enableInboundVoiceCall'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Enable Inbound Voice Call'
              />
              <SelectInput
                control={form.control}
                name='config.communication.enable_two_way_texting'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Enable Two-Way Texting'
              />
            </Section>

            <Section>
              <SectionLabel>Support</SectionLabel>
              <Input
                type='text'
                label='Phone'
                {...form.register('config.support.phone')}
              />
              <Input
                type='text'
                label='Email'
                placeholder='support@company-borrower.peach-sandbox.finance'
                {...form.register('config.support.email')}
              />
              <Input
                type='text'
                label='Department Name'
                {...form.register('config.support.departmentName')}
              />

              <Section>
                <SectionLabel>Mailing Address</SectionLabel>
                <Input
                  type='text'
                  label='City'
                  {...form.register('config.support.mailingAddress.city')}
                />
                <Input
                  type='text'
                  label='State'
                  {...form.register('config.support.mailingAddress.state')}
                />
                <Input
                  type='text'
                  label='Postal Code'
                  {...form.register('config.support.mailingAddress.postalCode')}
                />
                <Input
                  type='text'
                  label='Address Line 1'
                  {...form.register(
                    'config.support.mailingAddress.addressLine1',
                  )}
                />
                <Input
                  type='text'
                  label='Address Line 2'
                  {...form.register(
                    'config.support.mailingAddress.addressLine2',
                  )}
                />
              </Section>
            </Section>

            <Section>
              <SectionLabel>Payment</SectionLabel>
              <SelectInput
                control={form.control}
                name='config.payment.processedByPeach'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Processed By Peach'
              />
              <Input
                type='number'
                label='Transaction Limit Amount'
                {...form.register('config.payment.transactionLimitAmount', {
                  valueAsNumber: true,
                })}
              />
            </Section>

            <Section>
              <SectionLabel>Payment Types</SectionLabel>
              <SelectInput
                control={form.control}
                name='config.paymentTypes.ach'
                options={booleanOptions}
                placeholder='Select true/false'
                label='ACH'
              />
              <SelectInput
                control={form.control}
                name='config.paymentTypes.check'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Check'
              />
              <SelectInput
                control={form.control}
                name='config.paymentTypes.debitCard'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Debit Card'
              />
              <SelectInput
                control={form.control}
                name='config.paymentTypes.creditCard'
                options={booleanOptions}
                placeholder='Select true/false'
                label='Credit Card'
              />

              <Section>
                <SectionLabel>Balance Check</SectionLabel>
                <SelectInput
                  control={form.control}
                  name='config.paymentTypes.balanceCheck.plaid'
                  options={booleanOptions}
                  placeholder='Select true/false'
                  label='Plaid'
                />
              </Section>

              <Section>
                <SectionLabel>ACH Verification</SectionLabel>
                <SelectInput
                  control={form.control}
                  name='config.paymentTypes.achVerification.plaid'
                  options={booleanOptions}
                  placeholder='Select true/false'
                  label='Plaid'
                />
                <SelectInput
                  control={form.control}
                  name='config.paymentTypes.achVerification.microdeposit'
                  options={booleanOptions}
                  placeholder='Select true/false'
                  label='Microdeposits'
                />
              </Section>
            </Section>

            <Input
              type='text'
              label='Timezone'
              {...form.register('config.timezone')}
            />

            <Section>
              <SectionLabel>Open Hours</SectionLabel>

              <Section>
                <SectionLabel>Business Hours</SectionLabel>
                <Input
                  type='text'
                  label='Normal'
                  {...form.register('config.openHours.businessHours.normal')}
                />
              </Section>
            </Section>
          </Section>

          {error && <Error>{error.message}</Error>}
          <FormActions>
            <StyledButton type='submit' primary>
              Submit
            </StyledButton>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateCompany
