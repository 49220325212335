const roles = {
  api: {
    alias: 'api',
    actions: [
      'auth.short-lived-token:create',
      'bin.numbers:read',
      'card:update',
      'card:create',
      'card.issuer:list',
      'card:show.pan',
      'card.issuer:read',
      'card.issuer:delete',
      'card:show.cvv',
      'card.issuer:update',
      'card.issuer:create',
      'card:read',
      'card:list',
      'card:update.status',
      'card.tx.mapping:update',
      'card.tx.mapping:read',
      'case:create',
      'case:list',
      'case:read',
      'case:update',
      'case.type:create',
      'case.type:list',
      'case.type:read',
      'case.type:update',
      'company:update.sensitive',
      'company.loan:list',
      'contact:create',
      'contact:create.unconfirmed',
      'contact:delete',
      'contact:list',
      'contact:read',
      'contact:update',
      'contact:update.unconfirmed',
      'contactexporter:create',
      'contactexporter:delete',
      'contactexporter:list',
      'contactexporter:read',
      'contactexporter:update',
      'credit.agency:create',
      'credit.agency:list',
      'credit.agency:read',
      'credit.agency:update',
      'document:create',
      'document:delete',
      'document:read',
      'document:update',
      'draw:activate',
      'draw:amortize',
      'draw:charge.drawfee.variable',
      'draw:create',
      'draw:list',
      'draw:read',
      'draw.purchase:create',
      'draw.purchase:force',
      'draw.purchase:list',
      'draw.purchase:read',
      'draw.purchase:update',
      'employee:create',
      'employee:list',
      'employee:read',
      'employee:update',
      'event:read',
      'investor:create',
      'investor:list',
      'investor:read',
      'investor:update',
      'loan:accelerate',
      'loan:accelerate.reverse',
      'loan:amortize',
      'loan:cancel',
      'loan:cancel.dynamicfee',
      'loan:cancel.force',
      'loan:charge.dynamicfee',
      'loan:charge.dynamicfee.latefee',
      'loan:charge.dynamicfee.servicefee',
      'loan:chargeoff',
      'loan:chargeoff.reverse',
      'loan:close',
      'loan:create',
      'loan:delete',
      'loan:duedate.defer',
      'loan:fees.list',
      'loan:freeze',
      'loan:list',
      'loan.label:list',
      'loan.label:read',
      'loan.label:create',
      'loan.label:delete',
      'loan.label:update',
      'loan:pay',
      'loan:paymentplan.create',
      'loan:payoff',
      'loan:payoff.reverse',
      'loan:read',
      'loan:reimburse',
      'loan:unfreeze',
      'loan:update',
      'loan:update.force',
      'loan.action:create',
      'loan.advance:create',
      'loan.advance:list',
      'loan.advance:read',
      'loan.advance:update',
      'loan.investors:update',
      'loan:migrate',
      'loan.period:list',
      'loan.refund:create',
      'loan.refund:list',
      'loan.tape:create',
      'loan.tape:list',
      'loan.tape:read',
      'loan.tape:update',
      'loan.tape.type:create',
      'loan.tape.type:list',
      'loan.tape.type:read',
      'loan.tape.type:update',
      'loan.type:list',
      'loan.type:read',
      'merchant:create',
      'merchant:list',
      'merchant:read',
      'merchant:update',
      'obligation:list',
      'obligation:read',
      'obligation:refresh',
      'payment.instrument:create',
      'payment.instrument:delete',
      'payment.instrument:external',
      'payment.instrument:force',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:skip.verification',
      'payment.instrument:update',
      'person:create',
      'person:list',
      'person:list.searchable',
      'person:read',
      'person:read.searchable',
      'person:search',
      'person:update',
      'routing.numbers:read',
      'settlement:create',
      'settlement:list',
      'settlement.instrument:create',
      'settlement.instrument:list',
      'settlement.instrument:read',
      'settlement.instrument:update',
      'supercase:create',
      'supercase:list',
      'supercase:read',
      'supercase:update',
      'team:create',
      'team:list',
      'team:read',
      'team:update',
      'transaction:chargeback',
      'transaction:create.servicecredit',
      'user:create',
      'user:list',
      'user:read',
      'user:update',
    ],
  },
  admin: {
    alias: 'admin',
    actions: [
      'admin.portal:read',
      'autopay:create',
      'autopay:list',
      'autopay:read',
      'autopay:update',
      'bin.numbers:read',
      'card:create',
      'card:list',
      'card:read',
      'card:show.cvv',
      'card:show.pan',
      'card:update',
      'card:update.status',
      'card.issuer:create',
      'card.issuer:delete',
      'card.issuer:list',
      'card.issuer:read',
      'card.issuer:update',
      'card.tx.mapping:read',
      'card.tx.mapping:update',
      'case:create',
      'case:list',
      'case:read',
      'case:snooze',
      'case:update',
      'case.association:create',
      'case.association:delete',
      'case.type:list',
      'case.type:read',
      'communicator:render',
      'communicator:send',
      'communicator:send-templated-email',
      'communicator.confirmationcode:send',
      'communicator.template:activate',
      'communicator.template:create',
      'communicator.template:delete',
      'communicator.template:read',
      'communicator.template:update',
      'company:read',
      'company.loan:list',
      'compliance.code:execute',
      'compliance.code:list',
      'compliance.code:read',
      'compliance.codes:read',
      'compliance.rule:list',
      'compliance.rule:read',
      'consent:list',
      'consent:update',
      'contact:create',
      'contact:create.unconfirmed',
      'contact:delete',
      'contact:list',
      'contact:read',
      'contact:update',
      'contact:update.unconfirmed',
      'conversation:read',
      'conversation:update',
      'credit.agency:list',
      'credit.agency:read',
      'credit.reporting.status:create',
      'credit.reporting.status:list',
      'credit.reporting.status:read',
      'credit.reporting.status:update',
      'dni:create',
      'dni:delete',
      'dni:list',
      'dni:read',
      'dni:update',
      'document-upload-link:create',
      'document-upload-link:list',
      'document-upload-link:read',
      'document:create',
      'document:delete',
      'document:read',
      'document:update',
      'document:update.status',
      'draw:amortize',
      'draw:list',
      'draw:read',
      'draw.purchase:list',
      'draw.purchase:read',
      'due.date:read',
      'due.date:refresh',
      'due.date:update',
      'employee:create',
      'employee:list',
      'employee:read',
      'employee:update',
      'event:create',
      'event:read',
      'interaction:check',
      'interaction:create',
      'interaction:list',
      'interaction:list.searchable',
      'interaction:read',
      'interaction:read.searchable',
      'interaction:read.sensitive',
      'interaction:search',
      'interaction:update',
      'interaction.voice:create',
      'interaction.voice.inbound:update',
      'investor:list',
      'investor:read',
      'legal.representative:create',
      'legal.representative:list',
      'legal.representative:read',
      'legal.representative:update',
      'loan:amortize',
      'loan:cancel',
      'loan:cancel.dynamicfee',
      'loan:cancel.force',
      'loan:chargeoff',
      'loan:chargeoff.reverse',
      'loan:close',
      'loan:duedate.defer',
      'loan:fees.list',
      'loan:freeze',
      'loan:list',
      'loan:pay',
      'loan:paymentplan.create',
      'loan:payoff',
      'loan:payoff.reverse',
      'loan:read',
      'loan:refresh',
      'loan:reimburse',
      'loan:replay',
      'loan:schedule.defer',
      'loan:unfreeze',
      'loan:update',
      'loan:update.force',
      'loan.action:create',
      'loan.custompaymentplan:create',
      'loan.custompaymentplan:delete',
      'loan.custompaymentplan:list',
      'loan.custompaymentplan:read',
      'loan.custompaymentplan:refresh',
      'loan.interest:read',
      'loan.label:create',
      'loan.label:delete',
      'loan.label:list',
      'loan.label:read',
      'loan.label:update',
      'loan.promoprogram:list',
      'loan.refund:create',
      'loan.refund:list',
      'loan.scheduleoptions:get',
      'loan.type:list',
      'loan.type:read',
      'managerdashboard:read',
      'monitoring.status:list',
      'monitoring.status:read',
      'monitoring.status:update',
      'note:create',
      'note:read',
      'note:read.sensitive',
      'note:update',
      'notification.type:list',
      'notification.type:read',
      'obligation:create',
      'obligation:list',
      'obligation:patch',
      'obligation:read',
      'obligation:refresh',
      'obligation:update',
      'payment.instrument:create',
      'payment.instrument:delete',
      'payment.instrument:external',
      'payment.instrument:force',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:update',
      'permissions:list',
      'person:list',
      'person:list.searchable',
      'person:read',
      'person:read.searchable',
      'person:read.sensitive',
      'person:search',
      'person:update',
      'person.identity:create',
      'person.identity:delete',
      'person.identity:list',
      'person.identity:read',
      'person.identity:update',
      'promise.to.pay.plan:list',
      'promise.to.pay.plan:read',
      'purchase.dispute:create',
      'purchase.dispute:list',
      'purchase.dispute:read',
      'purchase.dispute:update',
      'rates:create',
      'rates:delete',
      'rates:list',
      'rates:update',
      'repaymentengine.notification:list',
      'role:assign',
      'role:create',
      'role:delete',
      'role:list',
      'role:read',
      'role:unassign',
      'role:update',
      'routing.numbers:read',
      'security.onetimecode:send',
      'settlement:create',
      'settlement:list',
      'statement:create',
      'statement:list',
      'statement:read',
      'statement:update',
      'supercase:bulk.create.dni',
      'supercase:bulk.delete.dni',
      'supercase:bulk.send',
      'supercase:bulk.update',
      'supercase:create',
      'supercase:list',
      'supercase:read',
      'supercase:update',
      'taskrouter:update.admin',
      'taskrouter.task:receive',
      'taskrouter.task.terminated:list',
      'taskrouter.task.terminated:read',
      'taskrouter.task.terminated:update',
      'taskrouter.task.unterminated:bulk.delete',
      'taskrouter.task.unterminated:list',
      'taskrouter.task.unterminated:read',
      'taskrouter.task.unterminated:update',
      'team:create',
      'team:list',
      'team:read',
      'team:update',
      'transaction:backdate',
      'transaction:cancel',
      'transaction:create',
      'transaction:create.downpayment',
      'transaction:create.onetimepayment',
      'transaction:create.reimbursement',
      'transaction:create.servicecredit',
      'transaction:delete',
      'transaction:external',
      'transaction:list',
      'transaction:list.global',
      'transaction:read',
      'transaction:reverse',
      'transaction:update',
      'transaction:update.global',
      'user:create',
      'user:list',
      'user:read',
      'user:update',
      'verification-type:list',
      'verification-type:read',
      'verification:create',
      'verification:list',
      'verification:read',
      'workflow:create',
      'workflow:delete',
      'workflow:read',
      'workflow:update',
      'workflow.type:read',
    ],
  },
  borrower: {
    alias: 'borrower',
    actions: [
      'autopay:create',
      'autopay:list',
      'autopay:read',
      'autopay:update',
      'bin.numbers:read',
      'card:list',
      'card:read',
      'case:list',
      'case:read',
      'case.type:list',
      'case.type:read',
      'communicator.confirmationcode:send',
      'communicator:render',
      'communicator:send',
      'consent:list',
      'consent:update',
      'contact:create',
      'contact:create.unconfirmed',
      'contact:delete',
      'contact:list',
      'contact:read',
      'contact:update',
      'contact:update.unconfirmed',
      'document:create',
      'document:read',
      'document:update',
      'document:update.status',
      'document-upload-link:read',
      'draw:list',
      'draw.purchase:list',
      'draw.purchase:read',
      'draw:read',
      'due.date:read',
      'due.date:update',
      'legal.representative:create',
      'legal.representative:list',
      'legal.representative:read',
      'legal.representative:update',
      'loan:fees.list',
      'loan:list',
      'loan:pay',
      'loan.promoprogram:list',
      'loan:read',
      'loan.refund:list',
      'loan.scheduleoptions:get',
      'loan.type:list',
      'loan.type:read',
      'loan:update',
      'monitoring.status:list',
      'monitoring.status:read',
      'monitoring.status:update',
      'obligation:list',
      'obligation:read',
      'payment.instrument:create',
      'payment.instrument:delete',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:update',
      'person:read',
      'person:update',
      'promise.to.pay.plan:list',
      'promise.to.pay.plan:read',
      'rates:list',
      'routing.numbers:read',
      'statement:list',
      'statement:read',
      'statement:update',
      'transaction:cancel',
      'transaction:create',
      'transaction:create.downpayment',
      'transaction:create.onetimepayment',
      'transaction:list',
      'transaction:read',
    ],
  },
  agent: {
    alias: 'agent',
    actions: [
      'autopay:create',
      'autopay:list',
      'autopay:read',
      'autopay:update',
      'bin.numbers:read',
      'card:create',
      'card.issuer:create',
      'card.issuer:delete',
      'card.issuer:list',
      'card.issuer:read',
      'card.issuer:update',
      'card:list',
      'card:read',
      'card:show.cvv',
      'card:show.pan',
      'card.tx.mapping:read',
      'card.tx.mapping:update',
      'card:update',
      'card:update.status',
      'case.association:create',
      'case.association:delete',
      'case:create',
      'case:list',
      'case:read',
      'case:snooze',
      'case.type:list',
      'case.type:read',
      'case:update',
      'communicator.confirmationcode:send',
      'communicator:render',
      'communicator:send',
      'communicator.template:read',
      'communicator.template:update',
      'company.loan:list',
      'company:read',
      'compliance.code:execute',
      'compliance.code:list',
      'compliance.code:read',
      'compliance.codes:read',
      'compliance.rule:list',
      'compliance.rule:read',
      'consent:list',
      'consent:update',
      'contact:create',
      'contact:create.unconfirmed',
      'contact:delete',
      'contact:list',
      'contact:read',
      'contact:update',
      'contact:update.unconfirmed',
      'conversation:read',
      'conversation:update',
      'credit.agency:list',
      'credit.agency:read',
      'credit.reporting.status:create',
      'credit.reporting.status:list',
      'credit.reporting.status:read',
      'credit.reporting.status:update',
      'dni:create',
      'dni:delete',
      'dni:list',
      'dni:read',
      'dni:update',
      'document:create',
      'document:delete',
      'document:read',
      'document:update',
      'document:update.status',
      'document-upload-link:create',
      'document-upload-link:list',
      'document-upload-link:read',
      'draw:amortize',
      'draw:list',
      'draw.purchase:list',
      'draw.purchase:read',
      'draw:read',
      'due.date:read',
      'due.date:refresh',
      'due.date:update',
      'employee:list',
      'employee:read',
      'event:create',
      'event:read',
      'interaction:check',
      'interaction:create',
      'interaction:list',
      'interaction:list.searchable',
      'interaction:read',
      'interaction:read.searchable',
      'interaction:search',
      'interaction:update',
      'interaction.voice:create',
      'interaction.voice.inbound:update',
      'investor:list',
      'investor:read',
      'legal.representative:create',
      'legal.representative:list',
      'legal.representative:read',
      'legal.representative:update',
      'loan.action:create',
      'loan:amortize',
      'loan:cancel',
      'loan:cancel.dynamicfee',
      'loan:cancel.force',
      'loan:chargeoff',
      'loan:chargeoff.reverse',
      'loan:close',
      'loan.custompaymentplan:create',
      'loan.custompaymentplan:delete',
      'loan.custompaymentplan:list',
      'loan.custompaymentplan:read',
      'loan.custompaymentplan:refresh',
      'loan:fees.list',
      'loan:freeze',
      'loan.interest:read',
      'loan.label:create',
      'loan.label:delete',
      'loan.label:list',
      'loan.label:read',
      'loan.label:update',
      'loan:list',
      'loan:pay',
      'loan:paymentplan.create',
      'loan:payoff',
      'loan:payoff.reverse',
      'loan.promoprogram:list',
      'loan:read',
      'loan:refresh',
      'loan.refund:list',
      'loan:reimburse',
      'loan.scheduleoptions:get',
      'loan.type:list',
      'loan.type:read',
      'loan:unfreeze',
      'loan:update',
      'loan:update.force',
      'monitoring.status:list',
      'monitoring.status:read',
      'monitoring.status:update',
      'note:create',
      'note:read',
      'note:update',
      'notification.type:list',
      'notification.type:read',
      'obligation:create',
      'obligation:list',
      'obligation:patch',
      'obligation:read',
      'obligation:refresh',
      'obligation:update',
      'payment.instrument:create',
      'payment.instrument:delete',
      'payment.instrument:external',
      'payment.instrument:force',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:update',
      'permissions:list',
      'person:list',
      'person:list.searchable',
      'person:read',
      'person:read.searchable',
      'person:search',
      'person:update',
      'promise.to.pay.plan:list',
      'promise.to.pay.plan:read',
      'purchase.dispute:create',
      'purchase.dispute:list',
      'purchase.dispute:read',
      'purchase.dispute:update',
      'rates:list',
      'repaymentengine.notification:list',
      'role:list',
      'role:read',
      'routing.numbers:read',
      'security.onetimecode:send',
      'settlement:create',
      'settlement:list',
      'statement:list',
      'statement:read',
      'statement:update',
      'supercase:bulk.create.dni',
      'supercase:bulk.delete.dni',
      'supercase:bulk.send',
      'supercase:bulk.update',
      'supercase:create',
      'supercase:list',
      'supercase:read',
      'supercase:update',
      'taskrouter.task:receive',
      'taskrouter.task.terminated:list',
      'taskrouter.task.terminated:read',
      'taskrouter.task.terminated:update',
      'taskrouter.task.unterminated:bulk.delete',
      'taskrouter.task.unterminated:list',
      'taskrouter.task.unterminated:read',
      'taskrouter.task.unterminated:update',
      'taskrouter:update.admin',
      'team:list',
      'team:read',
      'transaction:backdate',
      'transaction:cancel',
      'transaction:create',
      'transaction:create.downpayment',
      'transaction:create.onetimepayment',
      'transaction:create.reimbursement',
      'transaction:delete',
      'transaction:external',
      'transaction:list',
      'transaction:list.global',
      'transaction:read',
      'transaction:reverse',
      'transaction:update',
      'transaction:update.global',
      'user:list',
      'user:read',
      'verification:create',
      'verification:list',
      'verification:read',
      'verification-type:list',
      'verification-type:read',
      'workflow:create',
      'workflow:delete',
      'workflow:read',
      'workflow.type:read',
      'workflow:update',
    ],
  },
  supervisor: {
    alias: 'supervisor',
    actions: [
      'autopay:create',
      'autopay:list',
      'autopay:read',
      'autopay:update',
      'bin.numbers:read',
      'card:create',
      'card.issuer:create',
      'card.issuer:delete',
      'card.issuer:list',
      'card.issuer:read',
      'card.issuer:update',
      'card:list',
      'card:read',
      'card:show.cvv',
      'card:show.pan',
      'card.tx.mapping:read',
      'card.tx.mapping:update',
      'card:update',
      'card:update.status',
      'case.association:create',
      'case.association:delete',
      'case:create',
      'case:list',
      'case:read',
      'case:snooze',
      'case.type:list',
      'case.type:read',
      'case:update',
      'communicator.confirmationcode:send',
      'communicator:render',
      'communicator:send',
      'communicator.template:read',
      'communicator.template:update',
      'company.loan:list',
      'company:read',
      'compliance.code:execute',
      'compliance.code:list',
      'compliance.code:read',
      'compliance.codes:read',
      'compliance.rule:list',
      'compliance.rule:read',
      'consent:list',
      'consent:update',
      'contact:create',
      'contact:create.unconfirmed',
      'contact:delete',
      'contact:list',
      'contact:read',
      'contact:update',
      'contact:update.unconfirmed',
      'conversation:read',
      'conversation:update',
      'credit.agency:list',
      'credit.agency:read',
      'credit.reporting.status:create',
      'credit.reporting.status:list',
      'credit.reporting.status:read',
      'credit.reporting.status:update',
      'dni:create',
      'dni:delete',
      'dni:list',
      'dni:read',
      'dni:update',
      'document:create',
      'document:delete',
      'document:read',
      'document:update',
      'document:update.status',
      'document-upload-link:create',
      'document-upload-link:list',
      'document-upload-link:read',
      'draw:amortize',
      'draw:list',
      'draw.purchase:list',
      'draw.purchase:read',
      'draw:read',
      'due.date:read',
      'due.date:refresh',
      'due.date:update',
      'employee:list',
      'employee:read',
      'event:create',
      'event:read',
      'interaction:check',
      'interaction:create',
      'interaction:list',
      'interaction:list.searchable',
      'interaction:read',
      'interaction:read.searchable',
      'interaction:read.sensitive',
      'interaction:search',
      'interaction:update',
      'interaction.voice:create',
      'interaction.voice.inbound:update',
      'investor:list',
      'investor:read',
      'legal.representative:create',
      'legal.representative:list',
      'legal.representative:read',
      'legal.representative:update',
      'loan.action:create',
      'loan:amortize',
      'loan:cancel',
      'loan:cancel.dynamicfee',
      'loan:cancel.force',
      'loan:chargeoff',
      'loan:chargeoff.reverse',
      'loan:close',
      'loan.custompaymentplan:create',
      'loan.custompaymentplan:delete',
      'loan.custompaymentplan:list',
      'loan.custompaymentplan:read',
      'loan.custompaymentplan:refresh',
      'loan:duedate.defer',
      'loan:fees.list',
      'loan:freeze',
      'loan.interest:read',
      'loan.label:create',
      'loan.label:delete',
      'loan.label:list',
      'loan.label:read',
      'loan.label:update',
      'loan:list',
      'loan:pay',
      'loan:paymentplan.create',
      'loan:payoff',
      'loan:payoff.reverse',
      'loan.promoprogram:list',
      'loan:read',
      'loan:refresh',
      'loan.refund:create',
      'loan.refund:list',
      'loan:reimburse',
      'loan:schedule.defer',
      'loan.scheduleoptions:get',
      'loan.type:list',
      'loan.type:read',
      'loan:unfreeze',
      'loan:update',
      'loan:update.force',
      'managerdashboard:read',
      'monitoring.status:list',
      'monitoring.status:read',
      'monitoring.status:update',
      'note:create',
      'note:read',
      'note:read.sensitive',
      'note:update',
      'notification.type:list',
      'notification.type:read',
      'obligation:create',
      'obligation:list',
      'obligation:patch',
      'obligation:read',
      'obligation:refresh',
      'obligation:update',
      'payment.instrument:create',
      'payment.instrument:delete',
      'payment.instrument:external',
      'payment.instrument:force',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:update',
      'permissions:list',
      'person.identity:create',
      'person.identity:delete',
      'person.identity:list',
      'person.identity:read',
      'person.identity:update',
      'person:list',
      'person:list.searchable',
      'person:read',
      'person:read.searchable',
      'person:read.sensitive',
      'person:search',
      'person:update',
      'promise.to.pay.plan:list',
      'promise.to.pay.plan:read',
      'purchase.dispute:create',
      'purchase.dispute:list',
      'purchase.dispute:read',
      'purchase.dispute:update',
      'rates:create',
      'rates:delete',
      'rates:list',
      'rates:update',
      'repaymentengine.notification:list',
      'role:list',
      'role:read',
      'routing.numbers:read',
      'security.onetimecode:send',
      'settlement:create',
      'settlement:list',
      'statement:create',
      'statement:list',
      'statement:read',
      'statement:update',
      'supercase:bulk.create.dni',
      'supercase:bulk.delete.dni',
      'supercase:bulk.send',
      'supercase:bulk.update',
      'supercase:create',
      'supercase:list',
      'supercase:read',
      'supercase:update',
      'taskrouter.task:receive',
      'taskrouter.task.terminated:list',
      'taskrouter.task.terminated:read',
      'taskrouter.task.terminated:update',
      'taskrouter.task.unterminated:bulk.delete',
      'taskrouter.task.unterminated:list',
      'taskrouter.task.unterminated:read',
      'taskrouter.task.unterminated:update',
      'taskrouter:update.admin',
      'team:list',
      'team:read',
      'transaction:backdate',
      'transaction:cancel',
      'transaction:create',
      'transaction:create.downpayment',
      'transaction:create.onetimepayment',
      'transaction:create.reimbursement',
      'transaction:create.servicecredit',
      'transaction:delete',
      'transaction:external',
      'transaction:list',
      'transaction:list.global',
      'transaction:read',
      'transaction:reverse',
      'transaction:update',
      'transaction:update.global',
      'user:list',
      'user:read',
      'verification:create',
      'verification:list',
      'verification:read',
      'verification-type:list',
      'verification-type:read',
      'workflow:create',
      'workflow:delete',
      'workflow:read',
      'workflow.type:read',
      'workflow:update',
    ],
  },
  'peach-support': {
    alias: 'peach-support',
    actions: [
      // Permissioning for items within dev-tools
      'employee:create',
      'employee:update',
      'loan.label:create',
      'loan.label:delete',
      'loan.label:update',
      'person:update',
      'role:assign',
      'role:create',
      'role:delete',
      'role:unassign',
      'role:update',
      'statement:create',
      'statement:update',
      'team:create',
      'team:update',
      'user:create',
      'user:update',
      // Permissioning for read only items below this line
      'ach.file:list',
      'ach.file:read',
      'admin.portal:read',
      'autopay:list',
      'autopay:read',
      'bin.numbers:read',
      'borrowercampaign:list',
      'borrowercampaign:read',
      'borrowercampaign.run:read',
      'bulksender:list',
      'bulksender:read',
      'card.issuer:list',
      'card.issuer:read',
      'card:list',
      'card:read',
      'card.tx.mapping:read',
      'case:list',
      'case:read',
      'case.type:list',
      'case.type:read',
      'code.tag:list',
      'collection.agency:list',
      'collection.agency:read',
      'communicator:render',
      'communicator.template:read',
      'company.3rdpartyintegrations:read',
      'company.keys:list',
      'company.keys:read',
      'company:list',
      'company.loan:count',
      'company.loan:list',
      'company.loan:list.abridged',
      'company:read',
      'compliance.code:list',
      'compliance.code:read',
      'compliance.codes:read',
      'compliance.rule:list',
      // 'compliance.rule:list.decisions',
      'compliance.rule:read',
      'consent:list',
      'contactexporter:list',
      'contactexporter:read',
      'contact:list',
      'contact:read',
      'conversation:read',
      'credit.agency:list',
      'credit.agency:read',
      'credit.reporting.metro2:read',
      'credit.reporting.status:list',
      'credit.reporting.status:read',
      'disaster:list',
      'dni:list',
      'dni:read',
      'dni.type:list',
      'dni.type:read',
      'document:read',
      'document:read.sensitive',
      'document-upload-link:list',
      'document-upload-link:read',
      'draw:list',
      'draw.purchase:list',
      'draw.purchase:read',
      'draw:read',
      'due.date:read',
      'employee:list',
      'employee:read',
      'event:read',
      'funding.instrument.balance:get',
      'funding.instrument:list',
      'funding.instrument:read',
      'indexrates:list',
      'indexrates:read',
      'interaction:list',
      'interaction:list.searchable',
      'interaction:read',
      'interaction:read.searchable',
      'interaction:read.sensitive',
      'interaction:search',
      'investor:list',
      'investor:read',
      'legal.representative:list',
      'legal.representative:read',
      'loan.advance:list',
      'loan.advance:read',
      'loan.agenttools.dashboard:list',
      'loan.custompaymentplan:list',
      'loan.custompaymentplan:read',
      'loan:fees.list',
      'loan.ingest:get',
      'loan.interest:read',
      'loan.label:list',
      'loan.label:read',
      'loan:list',
      'loan:listall',
      'loan:listall.abridged',
      'loan.period:list',
      'loan.promoprogram:list',
      'loan.promoprogram:read',
      'loan.promoprogram.type:list',
      'loan.promoprogram.type:read',
      'loan:read',
      'loan.refund:list',
      'loan.refund:read',
      'loan.replay:list',
      'loan.replay:read',
      'loan.scheduleoptions:get',
      'loan.state:read',
      'loan.tape:list',
      'loan.tape:list.due',
      'loan.tape:read',
      'loan.tape:read.sensitive',
      'loan.tape.record:list',
      'loan.tape.type:list',
      'loan.tape.type:read',
      'loan.type:list',
      'loan.type:read',
      'loan:types.list',
      'managerdashboard:read',
      'merchant:list',
      'merchant:read',
      'monitoring.service:list',
      'monitoring.service:read',
      'monitoring.status:list',
      'monitoring.status:read',
      'note:read',
      'note:read.sensitive',
      'notification.type:list',
      'notification.type:read',
      'obligation:list',
      'obligation:read',
      'payment.instrument:list',
      'payment.instrument:read',
      'payment.instrument:read.sensitive',
      'payment.processor:list',
      'payment.processor:read',
      'permissions:list',
      'person.identity:list',
      'person.identity:read',
      'person.index:read',
      'person:list',
      'person:list.searchable',
      'person:read',
      'person:read.searchable',
      'person:read.sensitive',
      'person:search',
      'promise.to.pay.plan:read',
      'purchase.dispute:list',
      'purchase.dispute:read',
      'rates:list',
      'repaymentengine.notification:list',
      'rnd.record:list',
      'role:list',
      'role:read',
      'routing.numbers:read',
      'settlement.instrument:list',
      'settlement.instrument:read',
      'settlement:list',
      'settlement:list.internal',
      'snapshot.missing:list',
      'statement:list',
      'statement:read',
      'supercase:list',
      'supercase:read',
      'taskrouter.task.terminated:list',
      'taskrouter.task.terminated:read',
      'taskrouter.task.unterminated:list',
      'taskrouter.task.unterminated:read',
      'team:list',
      'team:read',
      'transaction:list',
      'transaction:list.global',
      'transaction:list.settlements',
      'transaction:read',
      'twpc:list',
      'twpc:read',
      'user:list',
      'user:read',
      'verification:list',
      'verification:read',
      'verification-type:list',
      'verification-type:read',
      'webhook.subscription:list',
      'webhook.subscription:read',
      'webhook.subscription:refire',
      'workflow:read',
      'workflow.type:read',
    ],
  },
}

export default roles
